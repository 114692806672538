<template>
    <div class="job-search">
      <h2>Buscar Trabajo</h2>
      <div class="filters">
        <label>
          Nombre de Puesto:
          <input v-model="filters.position" type="text" placeholder="Ej: Product Manager" />
        </label>
  
        <label>
          Industria:
          <select v-model="filters.industry">
            <option value="">Todas</option>
            <option value="tecnologia">Tecnología</option>
            <option value="finanzas">Finanzas</option>
            <option value="educacion">Educación</option>
            <!-- Agrega más opciones según sea necesario -->
          </select>
        </label>
  
        <label>
          Ubicación:
          <input v-model="filters.location" type="text" placeholder="Ej: Santiago, Chile" />
        </label>
  
        <label>
          Nivel de Experiencia:
          <select v-model="filters.experienceLevel">
            <option value="">Todos</option>
            <option value="junior">Junior</option>
            <option value="mid">Mid-Level</option>
            <option value="senior">Senior</option>
          </select>
        </label>
  
        <label>
          Tipo de Trabajo:
          <select v-model="filters.jobType">
            <option value="">Todos</option>
            <option value="fijo">Fijo</option>
            <option value="temporal">Temporal</option>
            <option value="contrato">Contrato</option>
          </select>
        </label>
  
        <label>
          Asistencia:
          <select v-model="filters.attendance">
            <option value="">Todas</option>
            <option value="remoto">Remoto</option>
            <option value="hibrido">Híbrido</option>
            <option value="presencial">Presencial</option>
          </select>
        </label>
  
        <label>
          Ordenar por:
          <select v-model="sortBy">
            <option value="relevancia">Relevancia</option>
            <option value="fecha">Fecha de Publicación</option>
            <option value="vistas">Más Vistas</option>
          </select>
        </label>
  
        <button @click="applyFilters">Aplicar Filtros</button>
      </div>
  
      <div class="job-results">
        <h3>Resultados de Búsqueda</h3>
        <div v-for="job in filteredJobs" :key="job.id" class="job-card">
          <h4>{{ job.title }}</h4>
          <p>{{ job.industry }} | {{ job.location }}</p>
          <p>{{ job.experienceLevel }} | {{ job.jobType }} | {{ job.attendance }}</p>
          <p>Publicado el: {{ job.postDate }}</p>
          <button>Ver Más</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "JobSearch",
    data() {
      return {
        filters: {
          position: "",
          industry: "",
          location: "",
          experienceLevel: "",
          jobType: "",
          attendance: "",
        },
        sortBy: "relevancia",
        jobs: [
          {
            id: 1,
            title: "Product Manager",
            industry: "Tecnología",
            location: "Santiago, Chile",
            experienceLevel: "Senior",
            jobType: "Fijo",
            attendance: "Remoto",
            postDate: "2024-09-01",
            views: 120,
          },
          {
            id: 2,
            title: "Analista Financiero",
            industry: "Finanzas",
            location: "Lima, Perú",
            experienceLevel: "Mid-Level",
            jobType: "Contrato",
            attendance: "Presencial",
            postDate: "2024-09-05",
            views: 80,
          },
          // Agrega más trabajos de ejemplo según sea necesario
        ],
      };
    },
    computed: {
      filteredJobs() {
        let filtered = this.jobs;
  
        // Aplicar filtros
        if (this.filters.position) {
          filtered = filtered.filter(job =>
            job.title.toLowerCase().includes(this.filters.position.toLowerCase())
          );
        }
  
        if (this.filters.industry) {
          filtered = filtered.filter(job => job.industry === this.filters.industry);
        }
  
        if (this.filters.location) {
          filtered = filtered.filter(job =>
            job.location.toLowerCase().includes(this.filters.location.toLowerCase())
          );
        }
  
        if (this.filters.experienceLevel) {
          filtered = filtered.filter(job => job.experienceLevel === this.filters.experienceLevel);
        }
  
        if (this.filters.jobType) {
          filtered = filtered.filter(job => job.jobType === this.filters.jobType);
        }
  
        if (this.filters.attendance) {
          filtered = filtered.filter(job => job.attendance === this.filters.attendance);
        }
  
        // Ordenar por criterio seleccionado
        if (this.sortBy === "fecha") {
          filtered = filtered.sort((a, b) => new Date(b.postDate) - new Date(a.postDate));
        } else if (this.sortBy === "vistas") {
          filtered = filtered.sort((a, b) => b.views - a.views);
        }
  
        return filtered;
      },
    },
    methods: {
      applyFilters() {
        // Este método se llama cuando el usuario hace clic en "Aplicar Filtros"
        // Aquí puedes hacer algo más, como enviar una solicitud a una API
        console.log("Filtros aplicados:", this.filters);
      },
    },
  };
  </script>
  
  <style scoped>
  .job-search {
    padding: 20px;
  }
  
  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filters label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  
  .job-results {
    margin-top: 20px;
  }
  
  .job-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  </style>